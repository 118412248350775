<template>
  <div class="login_box">
    <div class="login_left"></div>
    <div class="login_right">
      <div class="r_main">
        <img class="login_logo" src="../../assets/img/logo.png" alt="">
        <div class="r_tptle1">{{$t('main.text151')}}</div>
        <!-- <div class="r_tptle2">请在下方登录您的账户</div> -->

        <el-form class="form_box" novalidate>

          <div class="input_p_box">
            <input v-model.trim="email" type="text" required @input="handle_input(email,1)" @focus="inp_focus(email,1)" @blur="inp_blue(1)">
            <label>
              <span class="lab" :class="email_success?'lab_color':''">{{$t('main.text152')}}</span>
              <span class="lab2">{{$t('main.text153')}}</span>
            </label>
            <template v-if="email">
              <img v-show="inp1_close" @click="email=''" class="inp_r_icon inp_r_icon1" src="../../assets/img/clear_icon.png" alt="">
              <img v-show="email_success" class="inp_r_icon" src="../../assets/img/success_icon.png" alt="">
              <img v-show="!email_success" class="inp_r_icon" src="../../assets/img/error_icon.png" alt="">
            </template>
          </div>

          <div class="input_p_box">
            <input v-model.trim="auth_code" type="text" required 
            @input="handle_input(auth_code,2)" @focus="inp_focus(auth_code,2)" 
            @blur="inp_blue(2)"
            @keyup.enter="login_btn">
            <label>
              <span class="lab" :class="auth_code_success?'lab_color':''">{{$t('main.text154')}}</span>
              <span class="lab2">{{$t('main.text155')}}</span>
            </label>
            <span>
              <span v-show="show" class="code_btn" @click="send_email_btn">{{$t('main.text156')}}</span>
              <span v-show="!show" class="code_btn_no">{{count}} S</span>
            </span>
          </div>

          <div class="input_p_box">
            <input v-model.trim="pwd" type="password" required 
            @input="handle_input(pwd,3)" @focus="inp_focus(pwd,3)" 
            @blur="inp_blue(3)"
            @keyup.enter="login_btn">
            <label>
              <span class="lab" :class="pwd_success?'lab_color':''">{{$t('main.text157')}}</span>
              <span class="lab2">{{$t('main.text158')}}</span>
            </label>
            <template v-if="pwd">
              <img v-show="inp3_close" @click="pwd=''" class="inp_r_icon inp_r_icon1" src="../../assets/img/clear_icon.png" alt="">
              <img v-show="pwd_success" class="inp_r_icon" src="../../assets/img/success_icon.png" alt="">
              <img v-show="!pwd_success" class="inp_r_icon" src="../../assets/img/error_icon.png" alt="">
            </template>
          </div>

          <div class="input_p_box">
            <input v-model.trim="confirm_pwd" type="password" required 
            @input="handle_input(confirm_pwd,4)" @focus="inp_focus(confirm_pwd,4)" 
            @blur="inp_blue(4)"
            @keyup.enter="login_btn">
            <label>
              <span class="lab" :class="confirm_pwd_success?'lab_color':''">{{$t('main.text159')}}</span>
              <span class="lab2">{{$t('main.text160')}}</span>
            </label>
            <template v-if="pwd">
              <img v-show="inp4_close" @click="pwd=''" class="inp_r_icon inp_r_icon1" src="../../assets/img/clear_icon.png" alt="">
              <img v-show="confirm_pwd_success" class="inp_r_icon" src="../../assets/img/success_icon.png" alt="">
              <img v-show="!confirm_pwd_success" class="inp_r_icon" src="../../assets/img/error_icon.png" alt="">
            </template>
          </div>

          <div class="inp_btm_txt">
            <div class="inp_btm_txt1">
              <!-- <el-checkbox v-model="hold_login">记住登录</el-checkbox> -->
            </div>
            <div class="inp_btm_txt2" @click="$router.push({name:'login'});">{{$t('main.text161')}}</div>
          </div>

          <div class="login_btn_box">
            <el-button type="primary" class="login_btn" @click="login_btn">{{$t('main.text4')}}</el-button>
          </div>

        </el-form>

      </div>
    </div>
  </div>
</template>

<script>
import {send,forgetLoginPassword} from '@/api/api'
import md5 from 'js-md5';
import * as EmailValidator from 'email-validator';
// import crypto from "@/crypto/crypto";

export default {
  data() {
    return {
      email:"",
      pwd:"",
      inp1_close:false,
      inp3_close:false,
      email_success:false,
      pwd_success:false,
      hold_login:false,
      show: true,//60秒 倒计时
      count: '',
      timer: null,
      auth_code:  "",
      auth_code_success:false,
      inp4_close:false,
      confirm_pwd:"",
      confirm_pwd_success:false,
    };
  },
  mounted() {
    
  },
  methods: {
    handle_input(v,type){
      if(type==1 && v){
        this.inp1_close=true;
        // 邮箱格式
        if(this.email){
          this.email_success = EmailValidator.validate(this.email);
        }else{
          this.email_success = false;
        }
      }
      else if(type==2 && v){
        if(this.auth_code.length==6){
          this.pwd_success = true;
        }else{
          this.pwd_success = false;
        }
      }
      else if(type==3 && v){
        this.inp3_close=true;
        // 正则表达式：字母+数字，长度6-12 可以有特殊字符
        const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9(@#$%^&+=~!?_)]{6,18}$/;
        if(this.pwd){
          this.pwd_success = regex.test(this.pwd);
        }else{
          this.pwd_success = false;
        }
      }
      else if(type==4 && v){
        this.inp4_close=true;
        if(this.pwd===this.confirm_pwd){
          this.confirm_pwd_success = true;
        }else{
          this.confirm_pwd_success = false;
        }
      }
    },
    inp_focus(v,type){
      if(type==1 && v){
        this.inp1_close = true;
      }else if(type==2 && v){
        // auth_code
      }else if(type==3 && v){
        this.inp3_close = true;
      }else if(type==4 && v){
        this.inp4_close = true;
      }
    },
    inp_blue(type){
      if(type==1){
        setTimeout(() => {
          this.inp1_close = false;
        }, 200);
      }else if(type==2){
        // auth_code
      }else if(type==3){
        setTimeout(() => {
          this.inp3_close = false;
        }, 200);
      }else if(type==4){
        setTimeout(() => {
          this.inp4_close = false;
        }, 200);
      }
      
    },
    async login_btn(){
      if(!this.email){
        return this.msg("error",this.$t('main.text162'));
      }
      if(!this.email_success){
        return this.msg("error",this.$t('main.text163'));
      }
      if(!this.auth_code){
        return this.msg("error",this.$t('main.text164'));
      }
      if(!this.pwd){
        return this.msg("error",this.$t('main.text165'));
      }
      if(!this.pwd_success){
        return this.msg("error",this.$t('main.text166'));
      }
      if(!this.confirm_pwd){
        return this.msg("error",this.$t('main.text167'));
      }
      if(this.pwd!==this.confirm_pwd){
        return this.msg("error",this.$t('main.text168'));
      }
      const res = await forgetLoginPassword({
        email:this.email,
        password:md5(this.pwd),
        authCode:this.auth_code
      });
      if(res.data.code!==200){
        return this.msg("error",res.data.msg);
      }
      this.msg("success",this.$t('main.text169'));
      this.$router.push({name:"login"});
    },
    async send_email_btn(){
      if(!this.email){
        return this.msg("error",this.$t('main.text162'));
      }
      const res = await send({email:this.email,type:"forgetLoginPassword"});
      if(res.data.code!==205){
        return this.msg("error",res.data.msg);
      }
      this.getCode();
    },
    getCode(){
     const TIME_COUNT = 60;
     if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
           this.count--;
          } else {
           this.show = true;
           clearInterval(this.timer);
           this.timer = null;
          }
        }, 1000)
      }
    },

  },
};
</script>

<style scoped>
.login_box{
  display: flex;
  width: 100%;
  height: 100vh;
}
.login_left{
  width: 35%;
  height: 100%;
  background: url(../../assets/img/login_left_img.png) no-repeat;
  background-size: cover;
  background-position: center;
}
.login_right{
  flex: 1;
  position: relative;
}
.r_main{
  width: 60%;
  max-width: 490px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.r_tptle1{
  color: #333;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 15px;
}
.r_tptle2{
  color: #A9A9A9;
  font-size: 16px;
  font-weight: 500; 
  margin-bottom: 100px;
}
.inp_r_icon{
  width: 18px;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translate(0,-50%);
}
.inp_r_icon1{
  right: 40px;
  cursor: pointer;
}
.lab_color{
  color: #A9A9A9;
}
.inp_btm_txt{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}
.inp_btm_txt1 >>> .el-checkbox {
  color: #838383;
}
.inp_btm_txt1 >>> .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0A76E2;
}
.inp_btm_txt1 >>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0A76E2;
  border-color: #0A76E2;
}
.inp_btm_txt1 >>> .el-checkbox__inner{
  border-color: #838383;
}
.inp_btm_txt2{
  cursor: pointer;
  color: #0A76E2;
  font-size: 14px;
}
.login_btn,
.login_btn:hover
.login_btn:focus,
.login_btn:valid,
.login_btn:active,
.login_btn{
  width: 100%;
  height: 55px;
  /* line-height: 55px; */
  border-radius: 6px;
  background: #0A76E2;
  font-size: 14px;
}
</style>

<style scoped>
/* 输入框动画效果 */
.input_p_box{
  height: 55px;
  line-height: 55px;
  position: relative;
  border-radius: 3px;
  border: 1px solid #A9A9A9;
  margin-bottom: 20px;
}
.input_p_box input{
  outline: none;
  background: transparent;
  width: 100%;
  height: 50%;
  border: none;
  box-sizing: border-box;
  padding: 4px 15px;
  color: #333;
}
.input_p_box input:focus ~label,
.input_p_box input:valid ~label{
  left: 0;
  top: -20%;
  color: #0F72D5;
  font-size: 12px;
}
.input_p_box input:focus,
.input_p_box input:valid{
  position: absolute;
  left: 0;
  bottom: 3px;
}
.input_p_box:focus-within{
  border-color: #0A76E2;
}
.input_p_box .lab{
  display: none;
}
.input_p_box .lab2{
  display: block;
}
.input_p_box:focus-within .lab{
  display: block;
}
.input_p_box:focus-within .lab2{
  display: none;
}
.input_p_box input:valid ~label .lab{
  display: block;
}
.input_p_box input:valid ~label .lab2{
  display: none;
}
.input_p_box label{
  box-sizing: border-box;
  font-size: 14px;
  padding: 0 14px;
  color: #BCBCBC;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  transition: all 0.2s;
  pointer-events: none;
}
.login_btn_box{
  margin-top: 60px;
}
.login_logo{
  width: 280px;
  margin-left: -20px;
  margin-bottom: 20px;
}

.code_btn{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #0A76E2;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 5px 10px;
  font-size: 15px;
  color: #fff;
  line-height: initial;
  cursor: pointer;
}
.code_btn_no{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #141414;
  opacity: 0.2;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 5px 10px;
  font-size: 15px;
  color: #fff;
  line-height: initial;
}
</style>
